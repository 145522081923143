<template>
  <div
    v-if="bannerHome && bannerHome.data"
    class="banner-home"
  >
    <div class="banner-home-background">
      <figure class="only-desktop">
        <div
          class="sf-image--wrapper"
        >
          <nuxt-img
            preload
            format="webp"
            :src="bannerHome.data.image.desktop + '?w=1920&f=a'"
            :class="`sf-image sf-image-loaded`"
            alt="Banner"
          />
        </div>
      </figure>
      <figure class="only-mobile">
        <div
          class="sf-image--wrapper"
        >
          <nuxt-img
            preload
            format="webp"
            :src="bannerHome.data.image.mobile + '?w=768&f=a'"
            width="752"
            height="1049"
            sizes="sm:100vw md:50vw lg:400px"
            :class="`sf-image sf-image-loaded`"
            alt="Banner"
          />
        </div>
      </figure>
    </div>
    <div class="banner-home-details">
      <h1 class="banner-home-head">
        {{ bannerHome.data.heading }}
      </h1>
      <nuxt-link
        :to="localePath(bannerHome.data.link)"
        class="banner-home-button"
      >
        <span>{{ bannerHome.data.button }}</span>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import {
  ref, defineComponent, useFetch, useContext,
} from '@nuxtjs/composition-api';

import {
  contentGetters,
  useContent,
} from '@vue-storefront/gemini';
import { useI18n } from '../helpers/hooks/usei18n';

export default defineComponent({
  name: 'BannerHome',
  components: {},

  setup() {
    const { locale } = useI18n();
    const identifierHomePageBanner = `bannerHome-${locale}`;
    const bannerHome = ref({});
    const { error: nuxtError } = useContext();
    const {
      blocks,
      // error,
      loadBlocks,
    } = useContent(`cmsBlockBannerHome${identifierHomePageBanner}`);

    useFetch(async () => {
      await loadBlocks({ identifiers: [identifierHomePageBanner] });
      bannerHome.value = contentGetters.getCmsBlockContent(blocks.value)[identifierHomePageBanner];
      if (bannerHome.value === undefined) { nuxtError({ statusCode: 500 }); }
    });

    return {
      bannerHome,
      loadBlocks,
      identifierHomePageBanner,
      blocks,
    };
  },
});
</script>

<style lang="scss" scoped>
#home-loader {
  min-height: 60vh;
}

body .banner-home {
  overflow: hidden;
  position: relative;
  max-height: 100vh;

  figure {
    display: flex;
    margin: 0;
    justify-content: center;
  }

  .only-mobile {
    display: none;
  }

  .banner-home-background img {
    display: flex;
    width: 100%;
    position: relative;
    min-height: auto;
    max-height: 100%;
  }

  .banner-home-details {
    bottom: calc(11% - 1px);
    left: 50%;
    max-width: 692px;
    position: absolute;
    text-align: center;
    transform: translate(-50%, 0);
    width: 100%;

    .banner-home-head {
      color: #fff;
      font-family: 'Frank Ruhl Libre';
      font-size: 70px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 70px;
      margin-bottom: 15px;
      text-align: center;
    }

    .banner-home-button {
      background: transparent;
      border: 1px solid #fff;
      color: #fff;
      display: block;
      font-family: 'Spartan';
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 12px;
      margin: auto;
      min-width: 200px;
      outline: 0;
      text-transform: uppercase;
      transition-duration: 0.4s;
      width: max-content;
      margin-bottom: 2px;

      &:hover,
      &:active,
      &:focus {
        background: #fff;
        color: #333;
      }

      span {
        display: block;
        padding: 24px 20px 17px;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        font-family: inherit;
        font-weight: inherit;
      }
    }
  }
}

@media (max-width: 768px) {
  body .banner-home {
    .only-mobile {
      display: block;
    }

    .only-desktop {
      display: none;
    }

    .banner-home-details {
      bottom: 5.8%;
      width: 100%;

      .banner-home-head {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 14px;
        padding: 0 24px;
      }

      .banner-home-button {
        font-size: 10px;
        letter-spacing: 0px;
        line-height: 9px;
        min-width: 124px;

        span {
          padding: 19px 30px 15px;
        }
      }
    }
  }
}

.banner-home .sf-image {
  --image-width: 100%;
  min-height: 60vh;
  @include for-desktop {
    min-height: 95vh;
  }
  @media (min-width: 1440px) {
    min-height: 120vh;
  }
}

.banner-home .sf-image--wrapper {
  display: flex;
}
</style>
