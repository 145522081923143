<template>
  <div v-show="false" />
</template>

<script>
import { defineComponent, useMeta } from '@nuxtjs/composition-api';
import { useI18n } from '~/helpers/hooks/usei18n';

export default defineComponent({
  name: 'Organization',
  setup() {
    const { locale } = useI18n();
    const structuredOrganizationData = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: 'https://www.lazzarionline.com/',
      logo: 'https://www.lazzarionline.com/icons/logo.png',
      name: 'Lazzari',
      description: locale === 'en' ? 'On lazzarionline.com you will find women\'s clothing and accessories made entirely in Italy. From the fabrics used to the actual crafting process Lazzari clothes are made "like they used to be".' : 'Su lazzarionline.com trovi capi e accessori da donna realizzati interamente in Italia. Dai tessuti utilizzati al confezionamento, gli abiti Lazzari sono realizzati "come una volta". ',
      contactPoint: [{
        '@type': 'ContactPoint',
        telephone: '+39 045 7614582',
        email: 'info@lazzarionline.com',
        contactType: 'customer service',
      }],
    };
    useMeta(() => ({
      script: [
        {
          innerHTML: JSON.stringify(structuredOrganizationData),
          type: 'application/ld+json',
        },
      ],
      __dangerouslyDisableSanitizers: ['script'],
    }));
  },
  head: {},
});
</script>
